// import ExploreProductPage from '../pages/ExploreProductPage.vue'
// import ExploreSectionProducts from '../pages/ExploreSectionProducts.vue'

const routes = [
  {
    path: '/',
    component: () => import('layouts/SiteLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', component: () => import('pages/Index.vue'), name: 'Index' },
      { path: 'checkout', name: 'checkout', component: () => import('pages/Checkout.vue') },
      { path: 'pedidos', component: () => import('pages/Orders.vue') },
      { path: 'pedido/:id', component: () => import('pages/OrderDetail.vue') },
      { path: 'profile', component: () => import('pages/Profile.vue') },
      { path: 'cards', component: () => import('pages/Cards.vue') },
      { path: 'address', component: () => import('pages/AddressMobile.vue') },
      { path: 'support', component: () => import('pages/Support.vue') },
      { path: 'pedido/:id/track', component: () => import('pages/OrderTracking.vue') },
      { path: 'products/:category_id', component: () => import('pages/Product.vue') },
      { path: 'sections/:section_id', component: () => import('../pages/SectionProducts.vue') },
      { path: 'search', component: () => import('pages/Search.vue'), props: (route) => ({ query: route.query.q }) },

    ],
    beforeEnter: (to, from, next) => {
      if (!localStorage.token) next('/login')
      else next()
    },
  },
  {
    path: '/',
    component: () => import('layouts/LoginLayout.vue'),
    meta: {
      logout: true
    },
    children: [
      // { path: 'exploreSearch', component: () => import('pages/Search.vue'), props: (route) => ({ query: route.query.q }) },
      { path: 'logout', component: () => import('pages/Login.vue') },
      { path: 'login', component: () => import('pages/Login.vue'), name: 'Login' },
      { path: 'password-recovery', component: () => import('pages/PasswordRecovery.vue') },
      { path: 'signup', component: () => import('pages/Register.vue') },
      // { path: 'exploreProducts/:category_id', component:  ExploreProductPage },
      // { path: 'exploreSections/:section_id', component: ExploreSectionProducts },

      // { path: 'explore', component: () => import('pages/Explore.vue')}
    ]
  },
  // {
  //   path: '/explore',
  //   component: () => import('layouts/ExploreLayout.vue'),
  //   meta: {
  //     requiresAuth: false
  //   },
  //   children: [
  //     /* eslint-disable */
  //     { path: '', component: () => import('pages/ExplorePage.vue') }
  //   ]
  // },
  {
    path: '/aviso-de-privacidad',
    component: () => import('layouts/NavLayout.vue'),
    meta: {
      requiresAuth: false
    },
    children: [
      /* eslint-disable */
      { path: '', component: () => import('pages/Privacy.vue') },
    ]
  },
  {
    path: '/terminos-y-condiciones',
    component: () => import('layouts/NavLayout.vue'),
    meta: {
      requiresAuth: false
    },
    children: [
      /* eslint-disable */
      { path: '', component: () => import('pages/TOS.vue') },
    ]
  },
  {
    path: '/iosCards/:user_id/:signature',
    component: () => import('pages/CardIos.vue'),
     meta: {
      requiresAuth: false
    },
  }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '/:pathMatch(.*)*',
    component: () => import('pages/Error404.vue')
  })
}

export default routes
