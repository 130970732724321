/*
export function someMutation (state) {
}
*/
export const pushBasket = (state, payload) => {
  state.basket.push(payload)
}

export const popBasket = (state) => {
  state.basket.pop()
}

export const emptyBasket = (state) => {
  state.basket = []
}

export const removeFromBasket = (state, index) => {
  state.basket.splice(index, 1)
}

export const setAddress = (state, payload) => {
  state.address_id = payload
}

export const setTokenCard = (state, payload) => {
  state.card_token = payload
}

export const setAddressName = (state, payload) => {
  state.address_name = payload
}

export const toggleSlice = (state, payload) => {
  if ('sliced' in state.basket[payload.index]) {
    state.basket[payload.index].sliced = !state.basket[payload.index].sliced
  }
}

export const plusQuantity = (state, payload) => {
  if (payload.choice_type === 2 || payload.choice_type === '2') {
    var auxFloat = parseFloat(state.basket[payload.index].quantity_kg)
    auxFloat = auxFloat + 0.1
    state.basket[payload.index].quantity_kg = auxFloat
  } else if (payload.choice_type === 1 || payload.choice_type === '1') {
    state.basket[payload.index].quantity_pz++
  }
}

export const minusQuantity = (state, payload) => {
  if (payload.choice_type === 2 || payload.choice_type === '2') {
    var auxFloat = parseFloat(state.basket[payload.index].quantity_kg).toFixed(1)
    auxFloat = (auxFloat - 0.1).toFixed(1)
    if (auxFloat > 0) {
      state.basket[payload.index].quantity_kg = auxFloat
    }
  } else if (payload.choice_type === 1 || payload.choice_type === '1') {
    var auxFloatPz = parseFloat(state.basket[payload.index].quantity_pz)
    auxFloatPz = auxFloatPz - 1
    if (auxFloatPz > 0) {
      state.basket[payload.index].quantity_pz = auxFloatPz
    }
  }
}

export const updatePrice = (state, payload) => {
  state.basket[payload.index].price = payload.price
  state.basket[payload.index].type_discount = payload.type_discount
  state.basket[payload.index].discount = payload.discount
  state.basket[payload.index].price_with_discount = payload.price_with_discount
}
